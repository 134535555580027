import React from "react";
import { State } from "./State";
import { Action } from "./Actions";
import StoreContext from "./StoreContext";

const useStore = (): [State, React.Dispatch<Action>] => {
  const { state, dispatch } = React.useContext(StoreContext);
  return [state, dispatch];
};

export default useStore;
