export const typeInstallation = {
  B: { name: "Bûches", color: "#54C571" },
  G: { name: "Granulés", color: "#008000" },
  PL: { name: "Plaquettes", color: "#A52A2A" },
  T: { name: "Solaire thermique", color: "#FFA500" },
  PV: { name: "Photovoltaïque", color: "#0020C2" },
  RS: { name: "Restaurant", color: "#48b3d3" },
};

export type InstallationTypes = keyof typeof typeInstallation;

export function isInstallationType(
  v: string | undefined
): v is InstallationTypes {
  return typeof v !== "undefined" && v in typeInstallation;
}
