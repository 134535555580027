import { DbCustomer } from "./db/Models";
import { MapPoint } from "./store/State";
import { MapMarkerProps } from "../components/MapMarker";
import { isInstallationType, typeInstallation } from "screens/data";

export function customerToPoint(c: DbCustomer): MapPoint | undefined {
  if (c.location) {
    const type =
      c.install1 && isInstallationType(c.install1)
        ? typeInstallation[c.install1]
        : undefined;
    const color = type ? type.color : "#3a3a3a";
    return {
      id: c._id?.toHexString() || "",
      position: c.location.coordinates,
      color,
    };
  }
  return undefined;
}

export function markerToPoint(marker: { props: MapMarkerProps }): MapPoint {
  return {
    id: marker.props.id,
    position: marker.props.coordinates,
    color: marker.props.color,
  };
}
