import React from "react";
import reducer from "./Reducer";
import StoreContext from "./StoreContext";
import { initialState } from "./State";

const StoreProvider: React.FunctionComponent<{}> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
