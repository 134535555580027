const geocoderApiOptions = {
  endpoint: "https://api.tiles.mapbox.com",
  source: "mapbox.places",
  accessToken:
    "pk.eyJ1IjoiYW52b2siLCJhIjoiY2tidDNpenE2MDY2NjJ6bGNzZDdma2QzdSJ9.5gVPMtDHJ1acPljvurlVEA",
  country: "fr",
  language: "fr",
  types: "address,place",
  proximity: "6.0617998, 47.6302644",
};

function search(query: string): Promise<any> {
  return new Promise<any>((res, rej) => {
    var uri =
      geocoderApiOptions.endpoint +
      "/geocoding/v5/" +
      geocoderApiOptions.source +
      "/" +
      encodeURIComponent(query) +
      ".json" +
      "?access_token=" +
      geocoderApiOptions.accessToken +
      (geocoderApiOptions.proximity
        ? "&proximity=" + geocoderApiOptions.proximity
        : "") +
      (geocoderApiOptions.types
        ? "&types=" + encodeURIComponent(geocoderApiOptions.types)
        : "") +
      (geocoderApiOptions.types
        ? "&country=" + encodeURIComponent(geocoderApiOptions.country)
        : "") +
      (geocoderApiOptions.language
        ? "&language=" + encodeURIComponent(geocoderApiOptions.language)
        : "");

    fetch(uri)
      .then(function (response) {
        return response.json().then(function (json) {
          res(json.features);
        });
      })
      .catch(rej);
  });
}

export { search };
