import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Stitch, UserPasswordCredential } from "mongodb-stitch-browser-sdk";
import LoadingButton from "../components/LoadingButton";

const useStyles = makeStyles({
  root: {
    margin: "10px auto",
    maxWidth: 300,
  },
  actions: {
    justifyContent: "right",
  },
});

interface FormValues {
  username: string;
  password: string;
}

const Login: React.FunctionComponent<{}> = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    setError("");
    Stitch.defaultAppClient.auth
      .loginWithCredential(
        new UserPasswordCredential(values.username, values.password)
      )
      .then((user) => {
        if (!user) {
          throw new Error("User not found");
        }
      })
      .catch((err) => {
        console.error(err);
        setError("Accès refusé");
        setLoading(false);
      });
  };

  const initialValues = {
    username: process.env.REACT_APP_USERNAME || "",
    password: process.env.REACT_APP_PASSWORD || "",
  };

  return (
    <Card className={classes.root}>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Connexion
              </Typography>
              <TextField label="Email" name="username" fullWidth />
              <TextField
                label="Mot de passe"
                name="password"
                type="password"
                fullWidth
              />
              {error && <Alert severity="error">{error}</Alert>}
            </CardContent>
            <CardActions className={classes.actions}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
              >
                Connecter
              </LoadingButton>
            </CardActions>
          </form>
        )}
      </Form>
    </Card>
  );
};

export default Login;
