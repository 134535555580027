import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Hidden,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Dialog,
  List,
  withStyles,
  Badge,
} from "@material-ui/core";
import { Phone as TelIcon, Done as DoneIcon } from "@material-ui/icons";
import { DbCustomer } from "../lib/db/Models";
import {
  isInstallationType,
  typeInstallation,
} from "./data";

interface Props {
  customer: DbCustomer;
  onClick: (id: string) => void;
}

function getPhones(customer: DbCustomer): string[] {
  return [customer.fixe, customer.gsm1, customer.gsm2].filter(Boolean);
}

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 14,
    height: 14,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

function wrapAvatar(
  node: React.ReactElement,
  position: "top" | "bottom",
  install?: string
): React.ReactElement {
  if (!install || !isInstallationType(install)) return node;
  const backgroundColor = typeInstallation[install].color;
  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: position,
        horizontal: "right",
      }}
      badgeContent={<SmallAvatar style={{ backgroundColor }}> </SmallAvatar>}
    >
      {node}
    </Badge>
  );
}

const CustomerItem: React.FunctionComponent<Props> = React.memo(
  ({ customer, onClick }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClick = (id: string) => () => {
      onClick(id);
    };

    const handlePhoneClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      setDialogOpen(true);
    };

    const phones = getPhones(customer);
    const type =
      customer.install1 && isInstallationType(customer.install1)
        ? typeInstallation[customer.install1]
        : undefined;
    const backgroundColor = type ? type.color : "";

    const avatar = wrapAvatar(
      wrapAvatar(
        <Avatar style={{ backgroundColor }}>
          {customer.done ? <DoneIcon /> : ""}
        </Avatar>,
        "top",
        customer.install2
      ),
      "bottom",
      customer.install3
    );

    const primary = (
      <>
        {customer.nom} {customer.prenom}
        {Boolean(phones.length) && (
          <Hidden xsDown>
            {" - "}
            {phones.join(" - ")}
          </Hidden>
        )}
      </>
    );

    return (
      <React.Fragment key={customer._id!.toHexString()}>
        <ListItem onClick={handleClick(customer._id!.toHexString())} button>
          <ListItemAvatar>{avatar}</ListItemAvatar>
          <ListItemText
            primary={primary}
            secondary={`${customer.cp} ${customer.ville}`}
          />
          {Boolean(phones.length) && (
            <Hidden mdUp>
              <ListItemSecondaryAction>
                {phones.length === 1 && (
                  <a
                    href={`tel:${phones[0]}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton color="primary" aria-label="appeler">
                      <TelIcon />
                    </IconButton>
                  </a>
                )}
                {phones.length > 1 && (
                  <IconButton
                    color="primary"
                    aria-label="appeler"
                    onClick={handlePhoneClick}
                  >
                    <TelIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </Hidden>
          )}
        </ListItem>
        <Divider component="li" />
        {phones.length > 1 && (
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <List>
              {customer.fixe && (
                <ListItem component="a" href={`tel:${customer.fixe}`} button>
                  <ListItemText primary={customer.fixe} secondary="Fixe" />
                </ListItem>
              )}
              {customer.gsm1 && (
                <ListItem component="a" href={`tel:${customer.gsm1}`} button>
                  <ListItemText
                    primary={customer.gsm1}
                    secondary="Portable 1"
                  />
                </ListItem>
              )}
              {customer.gsm2 && (
                <ListItem component="a" href={`tel:${customer.gsm2}`} button>
                  <ListItemText
                    primary={customer.gsm2}
                    secondary="Portable 2"
                  />
                </ListItem>
              )}
            </List>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
);

export default CustomerItem;
