import React from "react";
import { List } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomerRepository from "../lib/db/CustomerRepository";
import PanelLoader from "../components/PanelLoader";
import useStore from "../lib/store/useStore";
import CustomerItem from "./CustomerItem";
import { MapPoint } from "../lib/store/State";
import { actionMapSetPoints } from "../lib/store/Actions";
import { customerToPoint } from "../lib/utils";
import { isInstallationType } from "./data";

const Customers: React.FunctionComponent<{}> = () => {
  const [customers, loading] = CustomerRepository.useMany(
    {},
    { nom: 1, prenom: 1 }
  );
  const [state, dispatch] = useStore();
  const history = useHistory();

  const handleCustomerClick = React.useCallback(
    (id: string) => {
      history.push("/customer/" + id);
    },
    [history]
  );

  const data = React.useMemo(() => {
    const search = state.search.toLowerCase().trim();
    return customers.filter(function (c) {
      if (state.filterDone && c.done) {
        return false;
      }
      let installationTypeMatch: boolean = false;
      if (state.filterInstallationType.length > 0) {
        if (
          isInstallationType(c.install1) &&
          state.filterInstallationType.includes(c.install1)
        ) {
          installationTypeMatch = true;
        } else if (
          isInstallationType(c.install2) &&
          state.filterInstallationType.includes(c.install2)
        ) {
          installationTypeMatch = true;
        } else if (
          isInstallationType(c.install3) &&
          state.filterInstallationType.includes(c.install3)
        ) {
          installationTypeMatch = true;
        }
      } else {
        installationTypeMatch = true;
      }
      if(!installationTypeMatch) {
        return false;
      }
      if (search.length === 0) {
        return true;
      }
      return (
        c.nom.toLowerCase().indexOf(search) > -1 ||
        c.prenom.toLowerCase().indexOf(search) > -1 ||
        c.cp.toString().toLowerCase().indexOf(search) > -1 ||
        c.ville.toLowerCase().indexOf(search) > -1
      );
    });
  }, [customers, state.search, state.filterDone, state.filterInstallationType]);

  React.useEffect(() => {
    const points = customers
      .filter(function (c) {
        if (state.filterDone && c.done) {
          return false;
        }
        return true;
      })
      .map<MapPoint | undefined>(customerToPoint)
      .filter(Boolean) as MapPoint[];
    dispatch(actionMapSetPoints(points));
  }, [customers, state.filterDone, dispatch]);

  if (loading) {
    return <PanelLoader />;
  }

  return (
    <List>
      {data.map((c) => (
        <CustomerItem
          key={c._id!.toHexString()}
          customer={c}
          onClick={handleCustomerClick}
        />
      ))}
    </List>
  );
};

export default Customers;
