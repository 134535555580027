import React from "react";
import {
  makeStyles,
  Box,
  BottomNavigationAction,
  Paper,
  Slide,
  Theme,
} from "@material-ui/core";
import {
  Build as MaintenanceIcon,
  PersonAdd as AddIcon,
  CheckBoxRounded as TypeChecked,
  CheckBoxOutlineBlank as TypeUnchecked,
} from "@material-ui/icons";
import PanelAppBar from "./PanelAppBar";
import Customers from "./Customers";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  actionToggleFilterDone,
  actionToggleFilterType,
} from "../lib/store/Actions";
import useStore from "../lib/store/useStore";
import {
  InstallationTypes,
  isInstallationType,
  typeInstallation,
} from "./data";

const useStyles = makeStyles((theme: Theme) => ({
  bottom: {
    display: "flex",
    justifyContent: "center",
  },
  bottomButton: {
    flexGrow: 1,
    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  typeButton: {
    flexGrow: 1,
    minWidth: 0,
    paddingTop: "8px !important",
    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  customers: {
    overflow: "auto",
  },
  typeIcon: { marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },
}));

const Panel: React.FunctionComponent<{}> = () => {
  const [state, dispatch] = useStore();
  const classes = useStyles();
  const history = useHistory();

  const handleAdd = () => {
    history.push("/customer");
  };

  const handleFilter = () => {
    dispatch(actionToggleFilterDone());
  };

  const handleFilterType = (type: InstallationTypes) => () => {
    dispatch(actionToggleFilterType(type));
  };

  return (
    <Slide in={true} direction="right">
      <Box display="flex" flexDirection="column" height="100%">
        <Box>
          <PanelAppBar />
        </Box>
        <Box className={classes.customers} flexGrow="1">
          <Customers key={state.customersKey} />
        </Box>
        <Box>
          <Paper className={classes.bottom} square>
            {Object.entries(typeInstallation).map(([k, v]) => {
              if (!isInstallationType(k)) {
                return null;
              }
              return (
                <BottomNavigationAction
                  key={k}
                  icon={
                    state.filterInstallationType.includes(k) ? (
                      <TypeChecked
                        className={classes.typeIcon}
                        htmlColor={v.color}
                      />
                    ) : (
                      <TypeUnchecked
                        className={classes.typeIcon}
                        htmlColor={v.color}
                      />
                    )
                  }
                  className={clsx({
                    [classes.typeButton]: true,
                    active: state.filterDone,
                  })}
                  onClick={handleFilterType(k)}
                />
              );
            })}
          </Paper>
        </Box>
        <Box>
          <Paper className={classes.bottom} square>
            <BottomNavigationAction
              label="Uniquement à faire"
              icon={<MaintenanceIcon />}
              className={clsx({
                [classes.bottomButton]: true,
                active: state.filterDone,
              })}
              onClick={handleFilter}
              showLabel
            />
            <BottomNavigationAction
              label="Ajouter"
              icon={<AddIcon />}
              className={classes.bottomButton}
              onClick={handleAdd}
              showLabel
            />
          </Paper>
        </Box>
      </Box>
    </Slide>
  );
};

export default Panel;
