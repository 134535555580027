import { State } from "./State";
import { Action } from "./Actions";

const Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SEARCH":
      return { ...state, search: action.value };
    case "TOGGLE_FILTER_DONE":
      return { ...state, filterDone: !state.filterDone };
    case "TOGGLE_FILTER_TYPE":
      if (state.filterInstallationType.includes(action.value)) {
        return {
          ...state,
          filterInstallationType: state.filterInstallationType.filter(
            (v) => v !== action.value
          ),
        };
      } else {
        return {
          ...state,
          filterInstallationType: [
            ...state.filterInstallationType,
            action.value,
          ],
        };
      }
    case "MAP_CLICK":
      return { ...state, mapClickPosition: action.value };
    case "MAP_SET_POINTS":
      return { ...state, mapPoints: action.points };
    case "INCREMENT_CUSTOMERS_KEY":
      return { ...state, customersKey: state.customersKey + 1 };
  }
};

export default Reducer;
