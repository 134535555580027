import React from "react";
import { State, initialState } from "./State";
import { Action } from "./Actions";

interface StoreContextInterface {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const StoreContext = React.createContext<StoreContextInterface>({
  state: initialState,
  dispatch: () => {},
});

export default StoreContext;