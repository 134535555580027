import React from "react";
import {
  makeStyles,
  Toolbar,
  IconButton,
  AppBar,
  Theme,
  InputBase,
  fade,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Menu as MenuIcon, Search as SearchIcon } from "@material-ui/icons";
import { Stitch } from "mongodb-stitch-browser-sdk";
import useStore from "../lib/store/useStore";
import {
  actionIncrementCustomersKey,
  actionSetSearch,
} from "../lib/store/Actions";
import CustomerRepository from "lib/db/CustomerRepository";
import ImportDialog from "./ImportDialog";
import ConfirmDialog from "components/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) => ({
  bottom: {
    display: "flex",
    justifyContent: "center",
  },
  bottomButton: {
    flexGrow: 1,
  },
  search: {
    flexGrow: 1,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const PanelAppBar: React.FunctionComponent<{}> = () => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | undefined>();
  const [isResetOpen, setIsResetOpen] = React.useState(false);
  const [isImportOpen, setIsImportOpen] = React.useState(false);
  const [state, dispatch] = useStore();
  const classes = useStyles();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(undefined);
  };

  const handleExit = () => {
    Stitch.defaultAppClient.auth.logout();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(actionSetSearch(value));
  };

  const handleResetConfim = () => {
    return CustomerRepository.update(
      { done: true },
      { $set: { done: false } }
    ).finally(() => {
      dispatch(actionIncrementCustomersKey());
      setIsResetOpen(false);
    });
  };

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Recherche…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={state.search}
            onChange={handleSearchChange}
            inputProps={{ "aria-label": "recherche" }}
            fullWidth
          />
        </div>
        <IconButton
          onClick={handleOpenMenu}
          edge="end"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              setIsResetOpen(true);
            }}
          >
            Remise à zéro entretiens
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              setIsImportOpen(true);
            }}
          >
            Import clients
          </MenuItem>
          <MenuItem onClick={handleExit}>Quitter</MenuItem>
        </Menu>
      </Toolbar>
      <ConfirmDialog
        open={isResetOpen}
        onCancel={() => setIsResetOpen(false)}
        onConfirm={handleResetConfim}
      >
        <div style={{ textAlign: "center" }}>
          Êtes vous sûr ? Cette action est irréversible
        </div>
      </ConfirmDialog>
      <ImportDialog
        open={isImportOpen}
        onClose={() => setIsImportOpen(false)}
      />
    </AppBar>
  );
};

export default PanelAppBar;
