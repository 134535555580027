import React from "react";
import ReactMapboxGl, { ZoomControl, Cluster } from "react-mapbox-gl";
import MapMarker, { MapMarkerProps } from "../components/MapMarker";
import useStore from "../lib/store/useStore";
import { actionMapClick } from "../lib/store/Actions";
import { MapPoint } from "../lib/store/State";
import MapCluster from "../components/MapCluster";
import { markerToPoint } from "../lib/utils";
import { useHistory } from "react-router";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiYW52b2siLCJhIjoiY2tidDNpenE2MDY2NjJ6bGNzZDdma2QzdSJ9.5gVPMtDHJ1acPljvurlVEA",
});

const initialLocation: [number, number] = [6.0617998, 47.6302644];
const initialZoom: [number] = [9];

const MapPanel: React.FunctionComponent<{}> = () => {
  const [state, dispatch] = useStore();
  const [location, setLocation] = React.useState(initialLocation);
  const [zoom, setZoom] = React.useState(initialZoom);
  const [currentPointId, setCurrentPointId] = React.useState<string>();
  const history = useHistory();

  const { mapPoints } = state;

  React.useEffect(() => {
    if (mapPoints.length === 1) {
      if (currentPointId !== mapPoints[0].id) {
        setZoom(z => [z[0] === 13 ? 12 : 13]);
      }
      setCurrentPointId(mapPoints[0].id);
      setLocation([mapPoints[0].position[0], mapPoints[0].position[1]]);
    } else {
      setCurrentPointId(undefined);
    }
  }, [mapPoints, currentPointId]);

  const clusterMarker = (
    coordinates: GeoJSON.Position,
    _: number,
    getLeaves: (
      limit?: number,
      offset?: number
    ) => Array<React.ReactElement<MapMarkerProps>>
  ) => {
    const leaves = getLeaves();
    return (
      <MapCluster
        key={JSON.stringify(coordinates)}
        coordinates={coordinates}
        points={leaves.map(markerToPoint)}
      />
    );
  };

  const handleMapClick = (_: any, e: any) => {
    dispatch(actionMapClick([e.lngLat.lng, e.lngLat.lat]));
  };

  const handleMarkerClick = (point: MapPoint) => () => {
    history.push("/customer/" + point.id);
  };

  return (
    <Map
      // eslint-disable-next-line
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: "100%",
        width: "100%",
      }}
      center={location}
      zoom={zoom}
      onClick={handleMapClick}
      renderChildrenInPortal={true}
    >
      <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick>
        {mapPoints.map(point => (
          <MapMarker
            key={point.id}
            id={point.id}
            onClick={handleMarkerClick(point)}
            coordinates={point.position}
            color={point.color}
          />
        ))}
      </Cluster>
      <ZoomControl />
    </Map>
  );
};

export default MapPanel;
