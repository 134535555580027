import React from "react";
import { DbModel, DocumentId } from "./Models";
import { getDb, saveModel, ensureObjectId } from "./utils";

type QueryHookReturnOne<T> = [
  T | null,
  boolean,
  (id?: DocumentId) => Promise<any>
];

type QueryHookReturnMany<T> = [
  T[],
  boolean,
  (query?: object, sort?: object) => Promise<any>
];

export interface Repository<T extends DbModel> {
  useOne: (id?: DocumentId) => QueryHookReturnOne<T>;
  useMany: (query?: object, sort?: object) => QueryHookReturnMany<T>;
  save: (obj: T) => Promise<T>;
  create: (documents: T[]) => Promise<any>;
  delete: (id: DocumentId) => Promise<any>;
  clear: (query?: object) => Promise<any>;
  update: (query: object, update: object) => Promise<any>;
}

export default function createRepository<Model extends DbModel>(
  collection: string
): Repository<Model> {
  const getCollection = function () {
    return getDb().collection<Model>(collection);
  };
  return {
    useOne: (id?: DocumentId) => {
      const [data, setData] = React.useState<Model | null>(null);
      const [isLoading, setIsLoading] = React.useState(true);

      const load = React.useCallback(async (id?: DocumentId) => {
        if (id) {
          setIsLoading(true);
          setData(await getCollection().findOne({ _id: ensureObjectId(id) }));
          setIsLoading(false);
        } else {
          setData(null);
          setIsLoading(false);
        }
      }, []);

      React.useEffect(() => {
        load(id);
      }, [id, load]);

      return [data, isLoading, load];
    },
    useMany: (query?: object, sort?: object) => {
      const [initialSort] = React.useState(sort || { createdAt: 1 });
      const [initialQuery] = React.useState(query);
      const [data, setData] = React.useState<Model[]>([]);
      const [isLoading, setIsLoading] = React.useState(true);

      const load = React.useCallback(
        async (query?: object, sort?: object) => {
          sort = sort || initialSort;
          setIsLoading(true);
          setData(await getCollection().find(query, { sort }).asArray());
          setIsLoading(false);
        },
        [initialSort]
      );

      React.useEffect(() => {
        load(initialQuery);
      }, [load, initialQuery]);

      return [data, isLoading, load];
    },
    save: (obj: Model): Promise<Model> => {
      return saveModel(obj, getCollection());
    },
    create: (documents: Model[]): Promise<any> => {
      return getCollection().insertMany(documents);
    },
    delete: (id: DocumentId) => {
      return getCollection().deleteOne({ _id: ensureObjectId(id) });
    },
    clear: (query?: object) => {
      return getCollection().deleteMany(query || {});
    },
    update: (query: object, update: object) => {
      return getCollection().updateMany(query, update);
    },
  };
}
