import React from "react";
import { makeStyles, Box, Hidden } from "@material-ui/core";
import Panel from "./Panel";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CustomerPanel from "./CustomerPanel";
import StoreProvider from "../lib/store/StoreProvider";
import MapPanel from "./MapPanel";

const useStyles = makeStyles({
  map: {
    background: "#ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const Main: React.FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <StoreProvider>
      <Router>
        <Box flexGrow="1" flexBasis="250px" style={{ overflow: "hidden" }}>
          <Switch>
            <Route path="/customer/:id?">
              <CustomerPanel />
            </Route>
            <Route>
              <Panel />
            </Route>
          </Switch>
        </Box>
        <Hidden smDown>
          <Box flexGrow="3" flexBasis="500px" className={classes.map}>
            <MapPanel />
          </Box>
        </Hidden>
      </Router>
    </StoreProvider>
  );
};

export default Main;
