import { InstallationTypes } from "screens/data";
import { MapPoint } from "./State";

export type Action =
  | { type: "SET_SEARCH"; value: string }
  | { type: "TOGGLE_FILTER_DONE" }
  | { type: "TOGGLE_FILTER_TYPE"; value: InstallationTypes }
  | { type: "MAP_CLICK"; value: GeoJSON.Position }
  | { type: "MAP_SET_POINTS"; points: MapPoint[] }
  | { type: "INCREMENT_CUSTOMERS_KEY" };

export function actionSetSearch(value: string): Action {
  return { type: "SET_SEARCH", value };
}

export function actionToggleFilterDone(): Action {
  return { type: "TOGGLE_FILTER_DONE" };
}

export function actionToggleFilterType(type: InstallationTypes): Action {
  return { type: "TOGGLE_FILTER_TYPE", value: type };
}

export function actionMapClick(coordinates: GeoJSON.Position): Action {
  return { type: "MAP_CLICK", value: coordinates };
}

export function actionMapSetPoints(points: MapPoint[]): Action {
  return { type: "MAP_SET_POINTS", points };
}

export function actionIncrementCustomersKey(): Action {
  return { type: "INCREMENT_CUSTOMERS_KEY" };
}
