import React from "react";
import {
  makeStyles,
  Box,
  BottomNavigationAction,
  Paper,
  Slide,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Theme,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Save as SaveIcon,
  FiberManualRecord as CircleIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import {
  TextField,
  Switches,
  Select,
  makeValidate,
  makeRequired,
} from "mui-rff";
import * as Yup from "yup";
import { DbCustomer, GeoPoint } from "../lib/db/Models";
import CustomerRepository from "../lib/db/CustomerRepository";
import PanelLoader from "../components/PanelLoader";
import PlacePickerButton from "../components/PlacePickerButton";
import useStore from "../lib/store/useStore";
import { actionMapSetPoints } from "../lib/store/Actions";
import { customerToPoint } from "../lib/utils";
import { typeInstallation } from "./data";
import ConfirmDialog from "components/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) => ({
  bottom: {
    display: "flex",
    justifyContent: "center",
  },
  bottomButton: {
    flexGrow: 1,
  },
  customer: {
    padding: theme.spacing(2),
    overflow: "auto",
  },
  fields: {
    padding: theme.spacing(2),
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  toggle: {
    justifyContent: "center",
    alignItems: "flex-end",
    display: "flex",
  },
  typeSelect: { minHeight: 24 },
  typeItem: { display: "flex", alignItems: "center" },
  typeIcon: { marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },
}));

type FormModel = Omit<DbCustomer, "location"> & {
  latitude?: number;
  longitude?: number;
};

const schema = Yup.object().shape<FormModel>({
  nom: Yup.string().required(),
  prenom: Yup.string(),
  adresse: Yup.string(),
  cp: Yup.string(),
  ville: Yup.string(),
  email: Yup.string(),
  fixe: Yup.string(),
  gsm1: Yup.string(),
  gsm2: Yup.string(),
  done: Yup.boolean().required(),
  latitude: Yup.number(),
  longitude: Yup.number(),
  install1: Yup.string(),
  install2: Yup.string(),
  install3: Yup.string(),
});

const fieldsValidate = makeValidate(schema);
const required = makeRequired(schema);

interface Params {
  id?: string;
}

const CustomerPanel: React.FunctionComponent<{}> = () => {
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Création client");
  const [customer, isLoading, load] = CustomerRepository.useOne();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<Params>();
  const [, dispatch] = useStore();

  const handleBack = () => {
    history.push("/");
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfim = () => {
    if (customer?._id) {
      return CustomerRepository.delete(customer?._id).then(() => {
        history.push("/");
      });
    }
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (customer) {
      const point = customerToPoint(customer);
      if (point) {
        dispatch(actionMapSetPoints([point]));
        return;
      }
    }
    dispatch(actionMapSetPoints([]));
  }, [customer, dispatch, isLoading]);

  const mutators = {
    setPosition: (args: any[], state: any, utils: any) => {
      const lng = args[0][0];
      const lat = args[0][1];
      utils.changeValue(state, "longitude", () => lng);
      utils.changeValue(state, "latitude", () => lat);
      dispatch(
        actionMapSetPoints([
          { id: params.id || "", position: [lng, lat], color: "bois" },
        ])
      );
    },
  };

  const handleSubmit = (values: FormModel) => {
    values.nom = values.nom || "";
    values.prenom = values.prenom || "";
    values.adresse = values.adresse || "";
    values.cp = values.cp || "";
    values.ville = values.ville || "";
    values.email = values.email || "";
    values.fixe = values.fixe || "";
    values.gsm1 = values.gsm1 || "";
    values.gsm2 = values.gsm2 || "";
    values.install1 = values.install1 || undefined;
    values.install2 = values.install2 || undefined;
    values.install3 = values.install3 || undefined;
    return fieldsValidate(values).then(errors => {
      if (errors && Object.values(errors).length > 0) {
        return errors;
      }
      const {
        nom,
        prenom,
        adresse,
        cp,
        ville,
        email,
        fixe,
        gsm1,
        gsm2,
        done,
        install1,
        install2,
        install3,
      } = values;
      let location: GeoPoint | undefined;
      if (Boolean(values.latitude) && Boolean(values.longitude)) {
        location = {
          type: "Point",
          coordinates: [values.longitude!, values.latitude!],
        };
      }
      const data: DbCustomer = {
        ...customer,
        nom,
        prenom,
        adresse,
        cp,
        ville,
        email,
        fixe,
        gsm1,
        gsm2,
        done,
        install1,
        install2,
        install3,
        location,
      };
      return CustomerRepository.save(data).then(() => {
        history.push("/");
        return {};
      });
    });
  };

  React.useEffect(() => {
    if (params.id) {
      setTitle("Modification client");
      load(params.id);
    } else {
      setTitle("Création client");
      load();
    }
  }, [params.id, load]);

  let values = {
    nom: "",
    prenom: "",
    adresse: "",
    cp: "",
    ville: "",
    email: "",
    fixe: "",
    gsm1: "",
    gsm2: "",
    done: false,
    type: "",
    ...customer,
    longitude: customer?.location?.coordinates[0],
    latitude: customer?.location?.coordinates[1],
  };

  const types = React.useMemo(
    () => [
      <MenuItem key="__" value="">
        Aucune
      </MenuItem>,
      ...Object.entries(typeInstallation).map(([k, v]) => (
        <MenuItem value={k} key={k}>
          <div className={classes.typeItem}>
            <CircleIcon className={classes.typeIcon} htmlColor={v.color} />
            {v.name}
          </div>
        </MenuItem>
      )),
    ],
    [classes]
  );

  return (
    <Slide in={true} direction="left">
      <Box display="flex" flexDirection="column" height="100%">
        {isLoading && (
          <>
            <Box>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <Typography variant="h6" component="h2">
                    &nbsp;
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <Box className={classes.customer} flexGrow="1">
              <PanelLoader />
            </Box>
          </>
        )}
        {!isLoading && (
          <Form<FormModel>
            onSubmit={handleSubmit}
            initialValues={values}
            validateOnBlur={true}
            mutators={mutators}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Box>
                  <AppBar position="static">
                    <Toolbar variant="dense">
                      <Typography variant="h6" component="h2">
                        {title}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </Box>
                <Box className={classes.customer} flexGrow="1">
                  <Paper className={classes.fields}>
                    <Grid spacing={1} container>
                      <Grid xs={12} lg={6} item>
                        <TextField
                          name="nom"
                          required={required.nom}
                          label="Nom"
                        />
                      </Grid>
                      <Grid xs={12} lg={6} item>
                        <TextField
                          name="prenom"
                          required={required.prenom}
                          label="Prénom"
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <TextField
                          name="adresse"
                          required={required.adresse}
                          label="Adresse"
                        />
                      </Grid>
                      <Grid xs={4} item>
                        <TextField
                          name="cp"
                          required={required.cp}
                          label="Code postal"
                        />
                      </Grid>
                      <Grid xs={8} item>
                        <TextField
                          name="ville"
                          required={required.ville}
                          label="Ville"
                        />
                      </Grid>
                      <Grid xs={5} item>
                        <TextField
                          name="longitude"
                          required={required.longitude}
                          label="Longitude"
                          disabled
                        />
                      </Grid>
                      <Grid xs={5} item>
                        <TextField
                          name="latitude"
                          required={required.latitude}
                          label="Latitude"
                          disabled
                        />
                      </Grid>
                      <Grid xs={2} item>
                        <PlacePickerButton />
                      </Grid>
                      <Grid xs={12} item>
                        <TextField
                          name="email"
                          required={required.email}
                          label="Email"
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <TextField
                          name="fixe"
                          required={required.fixe}
                          label="Tel fixe"
                        />
                      </Grid>
                      <Grid xs={12} lg={6} item>
                        <TextField
                          name="gsm1"
                          required={required.gsm1}
                          label="Portable 1"
                        />
                      </Grid>
                      <Grid xs={12} lg={6} item>
                        <TextField
                          name="gsm2"
                          required={required.gsm2}
                          label="Portable 2"
                        />
                      </Grid>
                      {[1, 2, 3].map(i => (
                        <Grid key={`install${i}`} xs={6} item>
                          <Select
                            name={`install${i}`}
                            label={`Installation ${i}`}
                            formControlProps={{ margin: "normal" }}
                            classes={{ select: classes.typeSelect }}
                          >
                            {types}
                          </Select>
                        </Grid>
                      ))}
                      <Grid xs={6} className={classes.toggle} item>
                        <Switches
                          color="primary"
                          name="done"
                          data={{ label: "Entretien fait", value: true }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
                <Box>
                  <Paper className={classes.bottom} square>
                    <BottomNavigationAction
                      label="Retour"
                      icon={<BackIcon />}
                      className={classes.bottomButton}
                      onClick={handleBack}
                      showLabel
                    />
                    {customer?._id && (
                      <>
                        <BottomNavigationAction
                          label="Supprimer"
                          icon={<DeleteIcon />}
                          className={classes.bottomButton}
                          onClick={handleDelete}
                          showLabel
                        />{" "}
                        <ConfirmDialog
                          open={isDeleteOpen}
                          onCancel={() => setIsDeleteOpen(false)}
                          onConfirm={handleDeleteConfim}
                        >
                          <div style={{ textAlign: "center" }}>
                            Êtes vous sûr de vouloir supprimer ce client ?
                          </div>
                        </ConfirmDialog>
                      </>
                    )}
                    <BottomNavigationAction
                      label="Enregistrer"
                      icon={
                        submitting ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <SaveIcon />
                        )
                      }
                      className={classes.bottomButton}
                      type="submit"
                      disabled={submitting}
                      showLabel
                    />
                  </Paper>
                </Box>
              </form>
            )}
          </Form>
        )}
      </Box>
    </Slide>
  );
};

export default CustomerPanel;
