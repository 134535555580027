import React from "react";
import { Stitch, StitchAuth, StitchUser } from "mongodb-stitch-browser-sdk";
import Login from "./screens/Login";
import Main from "./screens/Main";

Stitch.initializeDefaultAppClient(process.env.REACT_APP_STITCH_ID || "");

const App: React.FunctionComponent<{}> = () => {
  const [user, setUser] = React.useState<StitchUser | null | undefined>();

  const onUserLoggedIn = React.useCallback((auth: StitchAuth) => {
    setUser(auth.user);
  }, []);

  const onUserLoggedOut = React.useCallback(() => {
    setUser(null);
  }, []);

  React.useEffect(() => {
    Stitch.defaultAppClient.auth.addAuthListener({
      onUserLoggedIn,
      onUserLoggedOut,
    });
    if (Stitch.defaultAppClient.auth.user) {
      setUser(Stitch.defaultAppClient.auth.user);
    } else {
      setUser(null);
    }
    return () => {
      Stitch.defaultAppClient.auth.removeAuthListener({
        onUserLoggedIn,
        onUserLoggedOut,
      });
    };
  }, [onUserLoggedIn, onUserLoggedOut]);

  return (
    <div className={user ? "App App-logged" : "App"}>
      {user === null && <Login />}
      {user && <Main />}
    </div>
  );
};

export default App;
