import React from "react";
import { Marker } from "react-mapbox-gl";
import { Props as MarkerProps } from "react-mapbox-gl/lib/marker";
import { Room as PoiIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

export interface MapMarkerProps extends MarkerProps {
  id: string;
  color: string;
}

const useStyles = makeStyles({
  clickable: {
    cursor: "pointer",
  },
});

const MapMarker: React.FunctionComponent<MapMarkerProps> = React.memo(props => {
  const classes = useStyles();
  return (
    <Marker className={classes.clickable} {...props}>
      <PoiIcon htmlColor={props.color} fontSize="large" />
    </Marker>
  );
});

export default MapMarker;
