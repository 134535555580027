import { InstallationTypes } from "screens/data";

export interface MapPoint {
  position: GeoJSON.Position;
  color: string;
  id: string;
}

export interface State {
  search: string;
  filterDone: boolean;
  filterInstallationType: InstallationTypes[]
  mapClickPosition?: GeoJSON.Position;
  mapPoints: MapPoint[];
  customersKey: number;
}

export const initialState: State = {
  search: "",
  filterDone: true,
  filterInstallationType: [],
  mapPoints: [],
  customersKey: 0,
};
