import React from "react";
import { Place as PlaceIcon } from "@material-ui/icons";
import { Tooltip, IconButton } from "@material-ui/core";
import useStore from "../lib/store/useStore";
import { useForm } from "react-final-form";

const PlacePickerButton: React.FunctionComponent<{}> = () => {
  const [placing, setPlacing] = React.useState(false);
  const placingRef = React.useRef(false);
  const [state] = useStore();
  const form = useForm();

  const onClick = () => {
    setPlacing(v => !v);
  };

  React.useEffect(() => {
    if (placing) {
      if (placingRef.current) {
        form.mutators.setPosition(state.mapClickPosition);
      } else {
        placingRef.current = true;
      }
    } else {
      placingRef.current = false;
    }
  }, [state.mapClickPosition, placing, form.mutators]);

  return (
    <Tooltip title="Placer sur la carte">
      <IconButton onClick={onClick} color={placing ? "secondary" : "default"}>
        <PlaceIcon />
      </IconButton>
    </Tooltip>
  );
};

export default PlacePickerButton;
