import { makeStyles } from "@material-ui/core";
import React from "react";
import { Marker } from "react-mapbox-gl";
import { Props as MarkerProps } from "react-mapbox-gl/lib/marker";
import { MapPoint } from "../lib/store/State";

interface Props extends MarkerProps {
  points: MapPoint[];
}

function donutSegment(
  start: number,
  end: number,
  r: number,
  r0: number,
  color: string
) {
  if (start === 0 && end === 1) start = 0.00001;
  if (end > 1) end = 1;
  var a0 = 2 * Math.PI * (start - 0.25);
  var a1 = 2 * Math.PI * (end - 0.25);
  var x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  var x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  var largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    "L",
    r + r * x0,
    r + r * y0,
    "A",
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    "L",
    r + r0 * x1,
    r + r0 * y1,
    "A",
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />',
  ].join(" ");
}

const useStyles = makeStyles({
  clickable: {
    cursor: "pointer",
  },
});

function areEqual(prev: Props, next: Props): boolean {
  return (
    prev.coordinates[0] === next.coordinates[0] &&
    prev.coordinates[1] === next.coordinates[1]
  );
}

const MapCluster: React.FunctionComponent<Props> = React.memo(
  ({ coordinates, points }) => {
    const classes = useStyles();

    const colors = points.reduce<{ [key: string]: number }>((prev, cur) => {
      prev[cur.color] = prev[cur.color] ? prev[cur.color] + 1 : 1;
      return prev;
    }, {});

    const fontSize = 18;
    const r = 16;
    const w = r * 2;
    var r0 = Math.round(r * 0.75);
    var html =
      '<div><svg width="' +
      w +
      '" height="' +
      w +
      '" viewbox="0 0 ' +
      w +
      " " +
      w +
      '" text-anchor="middle" style="font: ' +
      fontSize +
      'px sans-serif; display: block">';
    const keys = Object.keys(colors).sort();
    let start = 0;
    for (const k of keys) {
      const end = start + colors[k] / points.length;
      html += donutSegment(start, end, r, r0, k);
      start = end;
    }
    html +=
      '<circle cx="' +
      r +
      '" cy="' +
      r +
      '" r="' +
      r0 +
      '" fill="white" /><text dominant-baseline="central" transform="translate(' +
      r +
      ", " +
      r +
      ')">' +
      points.length +
      "</text></svg></div>";

    return (
      <Marker
        coordinates={coordinates}
        className={classes.clickable}
        anchor="center"
      >
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </Marker>
    );
  },
  areEqual
);

export default MapCluster;
