import React from "react";
import {
  makeStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogActions,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import xlsx from "xlsx";
import { Alert } from "@material-ui/lab";
import CustomerRepository from "lib/db/CustomerRepository";
import { search } from "lib/geo";
import { DbCustomer } from "lib/db/Models";
import { makeValidateSync } from "mui-rff";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

type CustomerModel = Omit<DbCustomer, "location"> & {
  latitude?: number;
  longitude?: number;
};

const schema = Yup.object().shape<CustomerModel>({
  nom: Yup.string().required(),
  prenom: Yup.string(),
  adresse: Yup.string(),
  cp: Yup.string().required(),
  ville: Yup.string().required(),
  email: Yup.string(),
  fixe: Yup.string(),
  gsm1: Yup.string(),
  gsm2: Yup.string(),
  done: Yup.boolean().required(),
  install1: Yup.string(),
  install2: Yup.string(),
  install3: Yup.string(),
});

function validate(data: any): CustomerModel {
  const validator = makeValidateSync(schema);
  const values = {
    nom: data["Nom"] || "",
    prenom: data["Prénom"] || "",
    fixe: data["Téléphone fixe"] || "",
    gsm1: data["Portable 1"] || "",
    gsm2: data["Portable 2"] || "",
    email: data["Mail"] || "",
    adresse: data["Adresse"] || "",
    cp: data["Code postal"] || "",
    ville: data["Ville"] || "",
    install1: data["Installation 1"] || undefined,
    install2: data["Installation 2"] || undefined,
    install3: data["Installation 3"] || undefined,
    done: false,
  };
  if (values.fixe) {
    values.fixe = "0" + values.fixe;
  }
  if (values.gsm1) {
    values.gsm1 = "0" + values.gsm1;
  }
  if (values.gsm2) {
    values.gsm2 = "0" + values.gsm2;
  }
  const errors = Object.keys(validator(values));
  if (errors.length) {
    throw new Error(
      `Les valeurs ne sont pas correctes ou manquantes : ${errors.join(", ")}`
    );
  }
  return values;
}

interface Location {
  geometry: {
    coordinates: [number, number];
  };
}

function geoLoc(data: CustomerModel): Promise<Location> {
  return new Promise<Location>((res, rej) => {
    setTimeout(() => {
      const terms = data.adresse
        ? `${data.adresse} ${data.cp} ${data.ville}`
        : `${data.cp} ${data.ville}`;
      search(terms)
        .then(r => {
          if (!Array.isArray(r) || !r.length) {
            rej("Impossible de localiser (aucun résultats) : " + terms);
          } else {
            res(r[0]);
          }
        })
        .catch(e => {
          console.error(e);
          rej("Impossible de localiser (erreur) : " + terms);
        });
    }, 100);
  });
}

const allowTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

function importFile(file: Blob): Promise<Array<object>> {
  return new Promise<Array<object>>((res, rej) => {
    try {
      const reader = new FileReader();
      if (!allowTypes.includes(file.type)) {
        throw new Error("Format de fichier incorrect : " + file.type);
      }
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          try {
            const wb = xlsx.read(e.target.result, { type: "buffer" });
            if (!wb.Props) {
              throw new Error("Erreur de lecture format XSL");
            }
            const ws = wb.Sheets[wb.SheetNames[0]];
            const json = xlsx.utils.sheet_to_json<Array<object>>(ws);
            res(json);
          } catch (ex) {
            console.error(ex);
            rej(["Erreur lors de la lecture du fichier"]);
          }
        } else {
          rej(["Erreur lors de la lecture du fichier"]);
        }
      };
      reader.onerror = (e: ProgressEvent<FileReader>) => {
        console.error("reader.onerror", e);
        rej(["Erreur lors de la lecture du fichier"]);
      };
      reader.readAsArrayBuffer(file);
    } catch (ex) {
      console.error(ex);
      rej(["Erreur lors de la lecture du fichier"]);
    }
  });
}

interface ProgressData {
  value: number;
  max: number;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const ImportDialog: React.FunctionComponent<Props> = ({ open, onClose }) => {
  const openRef = React.useRef(false);
  const [loading, setLoading] = React.useState<string>();
  const [progress, setProgress] = React.useState<ProgressData>();
  const [errors, setErrors] = React.useState<string[]>([]);
  const [customers, setCustomers] = React.useState<DbCustomer[]>([]);
  const classes = useStyles();

  React.useEffect(() => {
    openRef.current = open;
    if (open) {
      setLoading(undefined);
      setProgress(undefined);
      setErrors([]);
      setCustomers([]);
    }
  }, [open]);

  const handleFileUpload = (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) {
      return;
    }
    setLoading("Lecture du fichier");
    setErrors([]);
    setCustomers([]);
    importFile(event.currentTarget.files[0])
      .then(async data => {
        setProgress({ value: 0, max: data.length });
        for (let i = 0; i < data.length; i++) {
          if (!openRef.current) break; // Abort processing if dialog is closed
          try {
            const customer: DbCustomer = validate(data[i]);
            const loc = await geoLoc(customer);
            customer.location = {
              type: "Point",
              coordinates: loc.geometry.coordinates,
            };
            setCustomers(s => [...s, customer]);
          } catch (ex) {
            setErrors(s => [...s, `Ligne ${i + 1} : ${ex.message}`]);
          }
          setProgress(s => {
            if (s) {
              return { ...s, value: i + 1 };
            }
          });
        }
      })
      .catch(setErrors)
      .finally(() => setLoading(undefined));
  };

  const handleImportClick = async () => {
    setProgress({ value: 0, max: customers.length });
    setLoading("Enregistrement des clients");
    try {
      await CustomerRepository.clear();
      while (customers.length > 0) {
        const batch = customers.splice(0, 50);
        await CustomerRepository.create(batch);
        setProgress(s => {
          if (s) {
            return { ...s, value: s.value + batch.length };
          }
        });
      }
      onClose();
    } catch (ex) {
      console.error(ex);
      setErrors(["Une erreur s'est produite"]);
      setLoading(undefined);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Import clients</DialogTitle>
      <DialogContent>
        {Boolean(errors.length) && (
          <Alert className={classes.alert} severity="error">
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </Alert>
        )}
        {loading && (
          <div>
            <div>
              {progress
                ? `Chargement ligne ${progress.value} sur ${progress.max}`
                : loading}
            </div>
            <LinearProgress
              variant="determinate"
              value={progress ? (progress.value / progress.max) * 100 : 0}
            />
          </div>
        )}
        {!loading && !Boolean(customers.length) && (
          <input type="file" onChange={handleFileUpload} />
        )}
        {!loading && Boolean(customers.length) && (
          <>
            <Alert className={classes.alert} severity="success">
              {customers.length} clients peuvent être intégrés
            </Alert>
            <Alert className={classes.alert} severity="warning">
              L'intégration va supprimer <strong>les clients existants</strong>{" "}
              et remettre à zéro les entretiens
            </Alert>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={onClose}>
          Annuler
        </Button>
        {!loading && Boolean(customers.length) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleImportClick}
          >
            Valider
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
