import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    margin: "0 36px 16px 36px",
    overflow: "hidden",
  },
  paper: {
    overflow: "hidden",
  },
  closeButton: {
    position: "absolute",
    top: 7,
    right: 21,
    fontSize: 14,
  },
  header: {
    height: 66,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 8,
    paddingTop: 0,
    boxShadow: "0 2px 4px 0 rgba(173, 173, 173, 0.5)",
    display: "flex",
    margin: "0 auto 10px auto",
    maxWidth: 375,
  },
  title: {
    color: "#ffffff",
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundImage: "linear-gradient(277deg, #ffb659 0%, #ff2d40 100%)",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    paddingRight: 48,
    paddingLeft: 24,
  },
  icon: {
    marginRight: 24,
    fontSize: 32,
  },
  submitButton: {
    backgroundColor: "#FF3041",
    borderRadius: 0,
  },
  body: {
    overflowY: "auto",
    padding: "50px 36px 0 36px",
  },
});

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<any> | void;
  children: React.ReactNode;
}

const ConfirmDialog: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  children,
}) => {
  open = open || false;
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const handleConfirm = () => {
    const prom = onConfirm();
    if (prom instanceof Promise) {
      setLoading(true);
      prom.finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="sm"
      classes={{ paper: classes.paper }}
      fullWidth
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDialog;
